/* eslint-disable */

import { useState, useEffect } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { SERVER_URL, ADMIN_API, UPLOADS_API } from "../../Config";
import TaskDialog from "./TaskDialog";
import TaskForm from "./TaskForm";
import { FaCamera, FaVideo, FaQuestionCircle, FaList } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import DraggableList from "./DraggableList";
import CreateApiClient from "../../API";
import LoadingBtn from "../mui/LoadingBtn";
import Loader from "../partials/Loader";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import UseAutocomplete from "../mui/Autocomplete";
import { MdDelete } from "react-icons/md";

const OPTIONS = [
  {
    value: "",
    index: 1
  },
  {
    value: "",
    index: 2,
  },
  {
    value: "",
    index: 3,
  },
  {
    value: "",
    index: 4,
  },
];

// from admin panel

const api = CreateApiClient();

export default function Tasks(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [tasksLoading, setTasksLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(null);
  const [newTaskLoader, setNewTaskLoader] = useState(false);
  const [taskType, setTaskType] = useState("");
  const [taskTitle, setTaskTitle] = useState("");
  const [taskName, setTaskName] = useState("");
  const [taskScore, setTaskScore] = useState("");
  const [taskAnswer, setTaskAnswer] = useState("");
  const [taskMultiple, setTaskMultiple] = useState([]);
  const [autoScore, setAutoScore] = useState(false);
  const [links, setLinks] = useState([]);
  const [moreLoading, setMoreLoading] = useState(false);
  const [taskIcon, setTaskIcon] = useState("");

  const [linkTxtValues, setLinkTxtValues] = useState({
    text1: "",
    text2: "",
    text3: "",
  });
  const [linkUrlValues, setLinkUrlValues] = useState({
    url1: "",
    url2: "",
    url3: "",
  });

  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Thumbnail");
  const [tasks, setTasks] = useState([]);
  const [chosenTasksOptions, setChosenTasksOptions] = useState([]);
  const [chosenTasks, setChosenTasks] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [optionsArray, setOptionsArray] = useState(OPTIONS);

  const [multiOptions, setMultiOptions] = useState([]);

  const [correct, setCorrect] = useState("");
  const [open, setOpen] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [lang, setLang] = useState("English");
  const [tasksLang, setTasksLang] = useState("all");
  const [showInFeed, setShowInFeed] = useState(true);
  const [taskCategory, setTaskCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [editCategoryLoading, setEditCategoryLoading] = useState(false);
  const [editedCategory, setEditedCategory] = useState("");
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [cancelToken, setCancelToken] = useState(null);
  const [query, setQuery] = useState("");
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [removeTasksLoading, setRemoveTasksLoading] = useState(false);
  const [taskThumbnail, setTaskThumbnail] = useState("");

  useEffect(() => {
    autocompleteTasks("*");
  }, []);

  useEffect(() => {
    getTasks(0);
    setSelectedTasks([]);
  }, [tasksLang]);

  const handleClickOpen = (value) => {
    setOpen(value);
  };

  const updateOption = (index, newValue) => {
    setOptionsArray((prevArray) =>
      prevArray.map((item) =>
        item.index === index ? { ...item, value: newValue } : item
      )
    );
  };

  const removeOption = (indexToRemove) => {
    setOptionsArray((prevOptions) => {
      // Remove the option with the given index
      const updatedOptions = prevOptions.filter(option => option.index !== indexToRemove);
  
      // Reassign indices sequentially
      return updatedOptions.map((option, idx) => ({
        ...option,
        index: idx + 1, // Indices start from 1
      }));
    });
  };

  const customIcons = [
    {
      value: "standard",
      label: "Standard",
    },
    {
      value: "bonus",
      label: "Bonus",
    },
    {
      value: "location",
      label: "Location",
    },
  ];

  const languages = [
    {
      name: "Hebrew",
      value: "Hebrew",
    },
    {
      name: "English",
      value: "English",
    },
  ];

  const handleClose = () => {
    setOpen(null);
    setTaskType("");
    setTaskTitle("");
    setTaskCategory("");
    setShowInFeed(true);
    setTaskIcon("");
    setTaskName("");
    setTaskScore("");
    setTaskAnswer("");
    setTaskMultiple([]);
    setAutoScore(false);
    setFile("");
    setFilename("");
    setLinks([]);
    setLinkUrlValues({
      url1: "",
      url2: "",
      url3: "",
    });

    setLinkTxtValues({
      text1: "",
      text2: "",
      text3: "",
    });
    setCorrect("");
    setTaskThumbnail("");

    setEditMode(false);
  };

  const handleEditOpen = (task) => {
    setShowInFeed(task.showInFeed);
    setTaskId(task._id);
    setTaskType(task.type);
    setTaskTitle(task.title ? task.title : "");
    setTaskAnswer(task.answer ? task.answer : "");
    setTaskName(task.name);
    setTaskCategory(task.category);
    setLang(task.language);
    setTaskScore(task.score);
    setTaskMultiple([]);
    setAutoScore(task.autoScore);
    setFile("");
    setFilename(task.filename);
    let _taskIcon = customIcons.find((i) => i.value === task.icon);
    if (_taskIcon) {
      setTaskIcon(_taskIcon.value);
    }
    // setLinks(task.links);
    setLinkUrlValues({
      url1: task.links[0].url,
      url2: task.links[1].url,
      url3: task.links[2].url,
    });

    setLinkTxtValues({
      text1: task.links[0].text,
      text2: task.links[1].text,
      text3: task.links[2].text,
    });
    setCorrect(task.correct_option);
    setOptionsArray(task.options);
   
  };

  const onCategoryChange = (e) => {
    setTaskCategory(e.target.value);
  };

  const onChangeShowInFeed = () => {
    setShowInFeed(!showInFeed);
  };

  const onChangeTaskScore = (e) => {
    setTaskScore(e.target.value);
  };

  const onChangeTaskAnswer = (e) => {
    setTaskAnswer(e.target.value);
  };

  const onChangeTaskTitle = (e) => {
    setTaskTitle(e.target.value);
  };

  const onChangeTaskOptions = (e) => {
    setTaskMultiple(e.target.value);
  };

  const onChangeTaskName = (e) => {
    setTaskName(e.target.value);
  };

  const onChangeAutoScore = () => {
    setAutoScore(!autoScore);
  };

  const onAddOptionClick = () => {
    setOptionsArray((prevOptions) => [
      ...prevOptions,
      { index: prevOptions.length + 1, value: "" }
    ]);
  }

  const onUploadFile = (e) => {
    let _file = files.find((f) => f.id === e.target.id);
    _file.setFile(e.target.files[0]);
    _file.setFilename(e.target.files[0].name);
    const reader = new FileReader();
    reader.onload = () => {
      setTaskThumbnail(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const onRemoveFile = (e) => {
    let _file = files.find((f) => `${f.id}_${f.id}` === e.target.id);
    _file.setFile("");
    _file.setFilename("");
  };

  const onUrlChange = (e, fieldName) => {
    setLinkUrlValues({ ...linkUrlValues, [fieldName]: e.target.value });
  };

  const onLinkTxtChange = (e, fieldName) => {
    setLinkTxtValues({ ...linkTxtValues, [fieldName]: e.target.value });
  };

  const files = [
    {
      id: "6",
      file: file,
      filename: filename,
      setFile: (e) => setFile(e),
      setFilename: (e) => setFilename(e),
    },
  ];

  const filterTasks = async (value) => {
    if (value && value._id) {
      console.log(value._id);
      await getTasks(0, value._id);
    }
  };

  const autocompleteTasks = async (q) => {
    setQuery(q);
    if (cancelToken) {
      cancelToken.cancel("Canceled");
    }
    const source = axios.CancelToken.source();
    setCancelToken(source);
    const url = `${SERVER_URL}/${ADMIN_API}/autocomplete-tasks?q=${q}`;
    const headers = {
      "auth-token": props.user.token,
    };

    const data = await api.fetchData("GET", url, headers, null, source.token);
    if (data && data.length > 0) {
      // OPTIONAL:
      // setTasks(data);
      setAutocompleteOptions(data);
    }
    return () => {
      if (cancelToken) {
        cancelToken.cancel("Canceled");
      }
    };
  };

  const onLangChange = (e) => {
    setLang(e.target.value);
  };

  const onCustomIconChange = (e) => {
    setTaskIcon(e);
  };

  const validLangs = ["hebrew", "english", "all"];
  const exclude = ["none"];

  const taskOptions = [
    {
      value: "photo",
      name: "Photo Challenge",
      icon: <FaCamera size={25} />,
      style: {
        width: isTabletOrMobile && "100%",
        minWidth: isTabletOrMobile && "250px",
        backgroundColor: "#D74230",
        borderColor: "#D74230",
        color: "white",
      },
      component: (
        <TaskForm
          onRemoveFile={onRemoveFile}
          onCustomIconChange={onCustomIconChange}
          customIcons={customIcons}
          taskIcon={taskIcon}
          files={files}
          taskThumbnail={taskThumbnail}
          onUploadFile={onUploadFile}
          text={"Photo Challenge Text"}
          taskName={taskName}
          onChangeTaskName={onChangeTaskName}
          taskScore={taskScore}
          onChangeTaskScore={onChangeTaskScore}
          onChangeAutoScore={onChangeAutoScore}
          autoScore={autoScore}
          onUrlChange={(e, fieldName) => onUrlChange(e, fieldName)}
          onLinkTxtChange={(e, fieldName) => onLinkTxtChange(e, fieldName)}
          linkTxtValues={linkTxtValues}
          linkUrlValues={linkUrlValues}
          titleText={"Task Title"}
          onChangeTaskTitle={onChangeTaskTitle}
          taskTitle={taskTitle}
          lang={lang}
          languages={languages}
          onLangChange={(e) => onLangChange(e)}
          type="photo"
          onChangeShowInFeed={onChangeShowInFeed}
          showInFeed={showInFeed}
          categories={categories}
          taskCategory={taskCategory}
          onCategoryChange={onCategoryChange}
        />
      ),
    },
    {
      value: "video",
      name: "Video Challenge",
      icon: <FaVideo size={25} />,
      style: {
        width: isTabletOrMobile && "100%",
        minWidth: isTabletOrMobile && "250px",
        backgroundColor: "#A364D1",
        borderColor: "#A364D1",
        color: "white",
      },
      component: (
        <TaskForm
          onCustomIconChange={onCustomIconChange}
          customIcons={customIcons}
          taskIcon={taskIcon}
          files={files}
          onUploadFile={onUploadFile}
          text={"Video Challenge Text"}
          taskName={taskName}
          onChangeTaskName={onChangeTaskName}
          taskScore={taskScore}
          onChangeTaskScore={onChangeTaskScore}
          onChangeAutoScore={onChangeAutoScore}
          autoScore={autoScore}
          onUrlChange={(e, fieldName) => onUrlChange(e, fieldName)}
          onLinkTxtChange={(e, fieldName) => onLinkTxtChange(e, fieldName)}
          linkTxtValues={linkTxtValues}
          linkUrlValues={linkUrlValues}
          titleText={"Task Title"}
          onChangeTaskTitle={onChangeTaskTitle}
          taskTitle={taskTitle}
          lang={lang}
          languages={languages}
          onLangChange={(e) => onLangChange(e)}
          type="video"
          onChangeShowInFeed={onChangeShowInFeed}
          showInFeed={showInFeed}
          categories={categories}
          taskCategory={taskCategory}
          onCategoryChange={onCategoryChange}
        />
      ),
    },
    {
      value: "qa",
      name: "Question & Answer",
      icon: <FaQuestionCircle size={25} />,
      style: {
        width: isTabletOrMobile && "100%",
        minWidth: isTabletOrMobile && "250px",
        backgroundColor: "#01BF9E",
        borderColor: "#01BF9E",
        color: "white",
      },
      component: (
        <TaskForm
          onCustomIconChange={onCustomIconChange}
          customIcons={[customIcons[0], customIcons[1]]}
          taskIcon={taskIcon}
          files={files}
          onUploadFile={onUploadFile}
          text={"Qustion Challenge Text"}
          onUrlChange={(e, fieldName) => onUrlChange(e, fieldName)}
          onLinkTxtChange={(e, fieldName) => onLinkTxtChange(e, fieldName)}
          linkTxtValues={linkTxtValues}
          linkUrlValues={linkUrlValues}
          taskName={taskName}
          onChangeTaskName={onChangeTaskName}
          taskScore={taskScore}
          onChangeTaskScore={onChangeTaskScore}
          onChangeAutoScore={onChangeAutoScore}
          autoScore={autoScore}
          titleText={"Task Title"}
          onChangeTaskTitle={onChangeTaskTitle}
          taskTitle={taskTitle}
          answerText={"Task Answer"}
          onChangeTaskAnswer={onChangeTaskAnswer}
          taskAnswer={taskAnswer}
          lang={lang}
          languages={languages}
          onLangChange={(e) => onLangChange(e)}
          type={"qa"}
          onChangeShowInFeed={onChangeShowInFeed}
          showInFeed={showInFeed}
          categories={categories}
          taskCategory={taskCategory}
          onCategoryChange={onCategoryChange}
        />
      ),
    },
    {
      value: "multi",
      name: "Multiple Choice",
      icon: <FaList size={25} />,
      style: {
        width: isTabletOrMobile && "100%",
        minWidth: isTabletOrMobile && "250px",
        backgroundColor: "#FABF45",
        borderColor: "#FABF45",
        color: "white",
      },
      component: (
        <TaskForm
          files={files}
          onCustomIconChange={onCustomIconChange}
          customIcons={customIcons}
          taskIcon={taskIcon}
          onUploadFile={onUploadFile}
          text={"Multiple Choice Challenge Text"}
          onUrlChange={(e, fieldName) => onUrlChange(e, fieldName)}
          onLinkTxtChange={(e, fieldName) => onLinkTxtChange(e, fieldName)}
          linkTxtValues={linkTxtValues}
          linkUrlValues={linkUrlValues}
          multiOptions={multiOptions}
          onAddOptionClick={onAddOptionClick}
          setMultiOptions={setMultiOptions}
          type="multi"
          optionsArray={optionsArray}
          removeOption={removeOption}
          updateOption={updateOption}
          correct={correct}
          setCorrect={(e) => setCorrect(e.target.value)}
          taskName={taskName}
          onChangeTaskName={onChangeTaskName}
          taskScore={taskScore}
          onChangeTaskScore={onChangeTaskScore}
          onChangeAutoScore={onChangeAutoScore}
          autoScore={autoScore}
          titleText={"Task Title"}
          onChangeTaskTitle={onChangeTaskTitle}
          taskTitle={taskTitle}
          lang={lang}
          languages={languages}
          onLangChange={(e) => onLangChange(e)}
          onChangeShowInFeed={onChangeShowInFeed}
          showInFeed={showInFeed}
          categories={categories}
          taskCategory={taskCategory}
          onCategoryChange={onCategoryChange}
        />
      ),
    },
  ];

  const isDuplicate = async (task) => {
    const url = `${SERVER_URL}/${ADMIN_API}/check-duplicate-task?title=${task.title}&category=${task.category}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("GET", url, headers, null);
    return data.duplicate;
  };

  const removeTask = async (task, editMode) => {
    setRemoveLoading(task._id);
    let filterQuery;
    if (validLangs.includes(tasksLang.toLowerCase())) {
      filterQuery = `lang=${tasksLang}`;
    } else {
      filterQuery = `category=${tasksLang}`;
    }
    const url = `${SERVER_URL}/${ADMIN_API}/delete-task?id=${task._id}&page=${page}&${filterQuery}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.updateData("POST", url, headers, null);
    if (page > 0) {
      setTasks([...tasks, ...data.data]);
    } else {
      setTasks(data.data);
    }
    setRemoveLoading(null);
    // let filteredTasks = props.tasks.filter((s) => s != task);
    // let filteredOldTasks = chosenTasks.filter((s) => s.value != task._id);
    // setTasks(filteredTasks);
    // setChosenTasks(filteredOldTasks);
  };

  const editTask = (task) => {
    setEditMode(true);
    handleEditOpen(task);
    handleClickOpen(task.type);
  };

  const onAddNewTask = async (type) => {
    setNewTaskLoader(true);
    let _links = [
      {
        text: linkTxtValues.text1,
        url: linkUrlValues.url1,
      },
      {
        text: linkTxtValues.text2,
        url: linkUrlValues.url2,
      },
      {
        text: linkTxtValues.text3,
        url: linkUrlValues.url3,
      },
    ];

    let obj = {
      type: type,
      title: taskTitle,
      name: taskName,
      category: taskCategory ? taskCategory : null,
      language: lang,
      hunts: [],
      score: taskScore,
      answer: taskAnswer,
      showInFeed: showInFeed,
      icon: taskIcon,
      options: optionsArray,
      correct_option: Number(correct),
      autoScore: autoScore,
      filename: filename,
      file: file,
      links: _links,
    };

    const isDup = await isDuplicate(obj);
    if (isDup) {
      window.alert("Duplicate Task! Change name or category.");
      setNewTaskLoader(false);
      return;
    }

    const formData = new FormData();
    formData.append("tasks", JSON.stringify(obj));
    formData.append("file", file);

    await uploadTask(formData);
    // setTasks([...tasks, obj]);
    setNewTaskLoader(false);
  };

  const uploadTask = async (task) => {
    let filterQuery;
    if (validLangs.includes(tasksLang.toLowerCase())) {
      filterQuery = `lang=${tasksLang}`;
    } else {
      filterQuery = `category=${tasksLang}`;
    }

    const url = `${SERVER_URL}/${UPLOADS_API}/add-task?page=${page}&${filterQuery}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.updateData("POST", url, headers, task);
    if (page > 0) {
      setTasks([...tasks, ...data.data]);
    } else {
      setTasks(data.data);
    }
    handleClose();
  };

  const loadMore = async () => {
    setMoreLoading(true);
    setPage(page + 1);
    await getTasks(page + 1);
    setMoreLoading(false);
  };

  const getTasks = async (page, searchById) => {
    setPage(page);
    if (page === 0) {
      setTasksLoading(true);
    }

    let filterQuery;
    if (searchById) {
      filterQuery = `id=${searchById}`;
    } else if (validLangs.includes(tasksLang.toLowerCase())) {
      filterQuery = `lang=${tasksLang}`;
    } else {
      filterQuery = `category=${tasksLang}`;
    }

    const url = `${SERVER_URL}/${ADMIN_API}/tasks?page=${page}&${filterQuery}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.fetchData("GET", url, headers, null);
    if (data.data.length + tasks.length < data.count) {
      setHasMoreResults(true);
    } else {
      setHasMoreResults(false);
    }
    if (page === 0) {
      setTasks(data.data);
    } else {
      setTasks([...tasks, ...data.data]);
    }
    setCategories(data.categories);
    setTasksLoading(false);
  };

  const updateTask = async () => {
    setNewTaskLoader(true);

    let _links = [
      {
        text: linkTxtValues.text1,
        url: linkUrlValues.url1,
      },
      {
        text: linkTxtValues.text2,
        url: linkUrlValues.url2,
      },
      {
        text: linkTxtValues.text3,
        url: linkUrlValues.url3,
      },
    ];

    let obj = {
      type: taskType,
      title: taskTitle,
      name: taskName,
      category: taskCategory ? taskCategory : null,
      language: lang,
      score: taskScore,
      showInFeed: showInFeed,
      answer: taskAnswer,
      icon: taskIcon,
      options: optionsArray,
      correct_option: Number(correct),
      autoScore: autoScore,
      filename: filename,
      file: file,
      links: _links,
    };

    // const isDup = await isDuplicate(obj);
    // if (isDup) {
    //   const confirmed = window.confirm("Duplicate Task! Continue updating?");
    //   if (!confirmed) {
    //     setNewTaskLoader(false);
    //     return;
    //   }
    // }

    const formData = new FormData();
    formData.append("tasks", JSON.stringify(obj));
    formData.append("file", file);

    let filterQuery;
    if (validLangs.includes(tasksLang.toLowerCase())) {
      filterQuery = `lang=${tasksLang}`;
    } else {
      filterQuery = `category=${tasksLang}`;
    }

    const url = `${SERVER_URL}/${UPLOADS_API}/update-task?page=${page}&id=${taskId}&${filterQuery}`;
    const headers = { "auth-token": props.user.token };

    const data = await api.updateData("POST", url, headers, formData);
    if (page > 0) {
      setTasks([...tasks, ...data.data]);
    } else {
      setTasks(data.data);
    }
    setNewTaskLoader(false);
    handleClose();
  };

  const chooseOldTask = (arr) => {
    setChosenTasks(arr);
  };

  const addOldTasks = () => {
    setTasks([...tasks, ...chosenTasks]);
    let notAdded = tasks.filter((x) => !chosenTasks.includes(x));
    setTasks(notAdded);
    setChosenTasks([]);
    setChosenTasksOptions([]);
  };
  const filterLang = (e) => {
    setTasksLang(e.target.value);
  };

  const updateCategory = async () => {
    setEditCategoryLoading(true);
    let priority = categories.find((c) => c.name === tasksLang).priority;
    const url = `${SERVER_URL}/${ADMIN_API}/update-task-category?name=${editedCategory}&priority=${priority}&oldName=${tasksLang}`;
    const headers = { "auth-token": props.user.token };

    await api.fetchData("POST", url, headers, null);
    setTasksLang(editedCategory);
    setEditedCategory("");
    getTasks(page);
    setEditCategoryLoading(false);
  };

  const selectTasks = (task) => {
    setSelectedTasks([...selectedTasks, task]);
  };

  const removeMultipleTasks = async () => {
    let confirmed = window.confirm(
      `Are you sure you want to delete ${selectedTasks.length} tasks?`
    );
    if (confirmed) {
      setRemoveTasksLoading(true);
      let filterQuery;
      if (validLangs.includes(tasksLang.toLowerCase())) {
        filterQuery = `lang=${tasksLang}`;
      } else {
        filterQuery = `category=${tasksLang}`;
      }
      const url = `${SERVER_URL}/${ADMIN_API}/delete-task?bulkMode=true&page=${page}&${filterQuery}`;
      const headers = { "auth-token": props.user.token };

      const body = { ids: selectedTasks };
      const data = await api.updateData("POST", url, headers, body);
      if (page > 0) {
        setTasks([...tasks, ...data.data]);
      } else {
        setTasks(data.data);
      }
      setRemoveTasksLoading(false);
      setSelectedTasks([]);
    }
  };

  return (
    <div style={{ width: "100%", margin: "auto" }}>
      <div
        className="english inpageSection"
        style={{ display: "block", width: "100%", backgroundColor: "#EEEEEE" }}
      >
        <h1>Tasks</h1>
        {!validLangs.concat(exclude).includes(tasksLang.toLowerCase()) && (
          <div
            style={{
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ width: isTabletOrMobile ? "50%" : "20%" }}>
              <TextField
                type="text"
                id="standard-text"
                label={tasksLang}
                onChange={(e) => setEditedCategory(e.target.value)}
                placeholder={tasksLang}
                value={editedCategory}
                fullWidth
                variant="standard"
              />
              {categories.find((c) => c.name === tasksLang) && (
                <>
                  {" "}
                  Priority:{" "}
                  {categories.find((c) => c.name === tasksLang).priority}
                </>
              )}
            </div>
            <div>
              <LoadingBtn
                style={{
                  // width: isTabletOrMobile ? "50%" : "20%",
                  // margin: "10px auto",
                  backgroundColor: "#fabe44",
                  color: "black",
                  borderColor: "#fabe44",
                }}
                txt="Update Category"
                loading={editCategoryLoading}
                onClick={updateCategory}
                // type="submit"
              />
            </div>
          </div>
        )}
        <br />
        <br />
        <hr style={{ margin: "0px" }} />
        <br />
        <ToggleButtonGroup
          color="primary"
          value={tasksLang}
          exclusive
          onChange={filterLang}
          aria-label="Platform"
          style={{
            display: isTabletOrMobile && "inline",
            justifyContent: "center",
            width: "100%",
            margin: "auto",
          }}
        >
          <ToggleButton value="all">All</ToggleButton>
          <ToggleButton value="Hebrew">Hebrew</ToggleButton>
          <ToggleButton value="English">English</ToggleButton>
          <ToggleButton value="none">NONE</ToggleButton>
          {categories
            .sort((a, b) => a.priority - b.priority)
            .map((item, index) => (
              <ToggleButton key={index} value={item.value}>
                {item.value}
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
        <br />
        <br />
        <div
          style={{
            display: isTabletOrMobile ? "block" : "flex",
            width: "100%",
            justifyContent: "center",
          }}
          className="flexForm flexButtons"
        >
          {taskOptions.map((item, index) => (
            <TaskDialog
              updateTask={updateTask}
              editMode={editMode}
              newTaskLoader={newTaskLoader}
              handleClickOpen={() => handleClickOpen(item.value)}
              handleClose={handleClose}
              open={open === item.value}
              key={index}
              item={item}
              onAddNewTask={() => onAddNewTask(item.value)}
              setOpen={setOpen}
              setTaskType={setTaskType}
              setTaskName={setTaskName}
              setTaskScore={setTaskScore}
              setTaskAnswer={setTaskAnswer}
              setTaskMultiple={setTaskMultiple}
              setAutoScore={setAutoScore}
              setLinks={setLinks}
            />
          ))}
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            paddingTop: "1rem",
          }}
          className="flexForm"
        >
          <UseAutocomplete
            options={autocompleteOptions}
            id="autocomplete-tasks"
            label={"Search task by name"}
            width={"100%"}
            onChange={(q) => autocompleteTasks(q)}
            query={query}
            submit={(value) => filterTasks(value)}
            clear={() => getTasks(0)}
          />
        </div>
        {selectedTasks && selectedTasks.length > 0 && (
          <LoadingBtn
            loading={removeTasksLoading}
            style={{
              backgroundColor: "#FABF45",
              borderColor: "#FABF45",
              color: "black",
              fontWeight: "bold",
              marginTop: "1rem",
            }}
            onClick={removeMultipleTasks}
            txt={"DELETE SELECTED TASKS"}
            customIcon={<MdDelete />}
          />
        )}
      </div>
      <div className="tasksTable" style={{}}>
        {tasksLoading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <Loader />
          </div>
        ) : (
          <DraggableList
            removeLoading={removeLoading}
            tasks={tasks}
            setTasks={setTasks}
            removeTask={(task) => {
              let confirmed = window.confirm(
                `Are you sure you want to delete ${task.name}?`
              );
              if (confirmed) {
                removeTask(task, props.editMode);
              }
            }}
            taskOptions={taskOptions}
            editTask={(task) => editTask(task)}
            selectTasks={(task) => selectTasks(task)}
            loadBtn={
              hasMoreResults && (
                <LoadingBtn
                  style={{
                    width: isTabletOrMobile ? "50%" : "20%",
                    margin: "10px auto",
                    backgroundColor: "#fabe44",
                    color: "black",
                    borderColor: "#fabe44",
                  }}
                  txt="Load More"
                  loading={moreLoading}
                  onClick={loadMore}
                  type="submit"
                />
              )
            }
          />
        )}
      </div>
    </div>
  );
}
