/* eslint-disable */

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function RowRadioButtonsGroup(props) {
  const { optionsCount } = props;
  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">
        Correct Option:
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={props.value}
        onChange={props.onChange}
      >
        {Array.from({ length: optionsCount }).map((_, index) => (
          <FormControlLabel value={index + 1} control={<Radio />} label={index + 1} />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
